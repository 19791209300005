<template>
  <div
    :style="getElementStyle"
    v-if="!loading && data.properties.filed_content != 'Hide'"
  >
    <el-row align="middle">
      <el-col
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
        v-if="!data.properties.hideLabel"
      >
        <label
          for="text"
          v-if="hasLabel && !data.properties.hideLabel"
          :style="getLabelStyles"
        >{{
          label||data.label
        }}</label>
        <span
        v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <span>
            <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
            >
            <i
              class="el-icon-info"
              slot="reference"
            ></i>
            </el-popover>
          </span>
      </el-col>
      <el-col v-else :span="showLabel  ? 4 : data.description ? 20 : 24" ref="colRef" :style="computedStyles">
        <span v-if="hasLabel" style="color: white; display: inline-block; height: 20px; opacity: 0;" ></span>
        <span
        v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold"
        >
          *
        </span>
      </el-col>
      <el-col :span="showLabel && !isDefalutPos ? 12 : 24">
        <el-date-picker
          v-if="isList && !isView && data.properties.filed_content != 'Hide'"
          type="datetime"
          :style="getStyle"
          prefix-icon="el-icon-date"
          v-model="form[data.key]"
          :disabled="isFromDocument ? false : checkReadonly()"
          :readonly="readonly()"
          :placeholder="data.placeholder || data.label"
          :format="'MM-dd-yyyy HH:mm:ss'"
          @change="applyFormRules"
        ></el-date-picker>

        <el-date-picker
          v-if="!isView && !isList && data.properties.filed_content != 'Hide'"
          prefix-icon="el-icon-date"
          v-model="form[data.key]"
          type="datetime"
          :placeholder="data.placeholder || data.label"
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :readonly="readonly()"
          :format="'MM-dd-yyyy HH:mm:ss'"
          @change="applyFormRules"
          :class="{ 'highlighted-border': highlight }"
        >
        </el-date-picker>
        <p
          v-if="isView && data.properties.filed_content != 'Hide'"
          :style="getStyle"
        >
          <template v-if="form[data.key]">{{
            form[data.key] | globalDateTimeFormat
          }}</template>
          <template v-else>{{ data.properties && data.properties.tableDefaultValue && data.properties.tableDefaultValue !== '' ? data.properties.tableDefaultValue : '-' }}</template>
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";

import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  name: "templates-formComponentsExecute-DateExecute",
  mixins: [userPermissionsHelper],
  components: {},
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "profilePage",
    "checkhideFields",
    "checkIsDisabled",
    "colorFields",
    "highlight"
  ],

  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    ...mapGetters("auth", ["getDefaultDateFormat"]),
    getStyle() {
      return `height:${this.data.height - 30}px; width: 100%`;
    },
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    computedStyles() {
    const styles = {};
    styles.display = 'flex';
    if (this.data?.styles?.labelAlignments) {
      styles.justifyContent = this.data.styles.labelAlignments;
    }
    return styles;
    },
  },
  data() {
    return {
      isList: false,
      showLabel: true,
      isDefalutPosq: true,
      loading: false
    };
  },
  mounted() {
    this.loading = true;
    this.defaultMethod();
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    if(this.form && this.data && this.form[this.data.key] && this.form[this.data.key] !== "undefined"){
      this.$set(this.form, this.data.key, this.form[this.data.key]);
    }else{
      this.$set(this.form, this.data.key, "");
    }
    
    this.loading = false;
    this.$emit("applyFormRules");
  },
  methods: {
    defaultMethod(){
      if (this.data.default_value && this.form &&this.data.key && !this.form[this.data.key]) {
        // localTimezone = 'Pacific/Midway'
        let localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        let dateTime = this.data.properties.defaultTodayDateTime ? this.form[this.data.key] == undefined   ? new Date() : this.data.default_value : this.data.default_value
        this.$set(
          this.form, this.data.key, 
          dateTime,
          moment(dateTime).tz(localTimezone)
        );
      }
    if (this.data.properties.autofill_datetime_response== true && !this.isView && !this.form[this.data.key]) {
      // let localTimezone = 'Pacific/Midway'
         let localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        let currentTime = moment().tz(localTimezone)
        this.$set(
          this.form,
          this.data.key,
          currentTime
        );
    }
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type == "DATE_TIME") {
          this.isList = true;
          //this.form[this.data.key] = this.getGlobalVariableById.value;
          this.$set(this.form,this.data.key,this.getGlobalVariableById.value)
        }
      }
    },

    readonly() {
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          if (this.data.styles.labelStyle == "right") {
            this.isDefalutPos = false;
          }
        }
        //If it's an entity varaible, have to check access
        if (
          this.data.field_assignable === "read_only" &&
          this.data.is_entity_variable
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    }
  },
  watch: {
    // $props: {
    //   handler() {
    //     this.defaultMethod();
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
};
</script>

<style lang="scss">
</style>